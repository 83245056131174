.type2 {
  padding: 1rem;

  .list_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .item {
      width: 8rem;
      height: 8rem;
      margin: 0 16px 20px;
      border: 1px solid #dadada;
      border-radius: 50%;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -4px rgba(0, 0, 0, 0.1);
      transition-duration: 0.15s;

      img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media screen and (max-width: 865px) {
  .type2 {
    .list_wrapper {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 768px) {
  .type2 {
    padding: 0;
    .list_wrapper {
      .item {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
